import React from 'react';
import styles from "./modal.module.css"

import {window} from "browser-monads"
class Modal extends React.Component {
    constructor (props){
        super(props);
        this.state = {
            modal : false
        }
    }
    render() { 
        let {closeModal, isActive} = this.props;
        let newClass = isActive ? `${styles.active}` : "";
        let currentUrl = window.location.origin;
        return ( 
            
            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              
              <div className={styles.popup_inner}> 
                 
                 <p>
                 You are leaving {currentUrl}. Links to all outside websites are provided as a resource to our visitors. Outside websites are neither owned or controlled by Neurocrine UK. Neurocrine UK accepts no responsibility for the content of other websites.
                 </p> 
                 <a href="https://twitter.com/DiurnalLtd?s=20" target="blank" onClick={closeModal} className={"btn"} >
                    Confirm
                 </a>
     
                 <button onClick={closeModal} style={{marginLeft: '10px'}} className={"btn"}>
                    Cancel
                 </button>  
                 </div> 
            </div>
         );
    }
}
 
export default Modal;